import React, { useState } from 'react';
import { 
  Box, 
  Drawer, 
  Typography, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';

function HistoryDrawer({ open, onClose, conversations, onSelect, onDelete, theme }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const handleDeleteClick = (id) => {
    setDeletingId(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(deletingId);
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: 320,
            borderTopLeftRadius: '16px',
            borderBottomLeftRadius: '16px',
            padding: '20px',
            backgroundColor: theme.palette.background.paper
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>历史对话</Typography>
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            {conversations.map((conv) => (
              <Box
                key={conv.id}
                sx={{
                  p: 2,
                  mb: 1,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.04)',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => onSelect(conv.id)}
              >
                <Typography variant="subtitle2" noWrap>
                  {conv.title || '未命名对话'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(conv.created_at).toLocaleString()}
                </Typography>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(conv.id);
                  }}
                  size="small"
                  sx={{
                    color: '#666',
                    '&:hover': {
                      color: '#ff4d4f'
                    }
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: { 
            minWidth: '300px',
            '&:focus': {
              outline: 'none'
            }
          }
        }}
      >
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            确定要删除这个对话吗？此操作不可恢复。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>取消</Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error"
            autoFocus
          >
            删除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HistoryDrawer;