// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px;
}

/* .card-out {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
} */

/* .card {
  background-color: transparent;
} */

body {
  background-color: #000000;
}

.title,
.title-state,
.tap-bot {
  color: #ffffff;
}

.title-bot {
  color: #cccccc;
}

.ant-input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.but {
  background-color: #4ecca3;
  color: #ffffff;
}

.but:hover {
  background-color: #45b7a0 !important;
}

.tap-bot{
  margin-left:120px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}
.tap-bot:hover {
  color: white;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/forget.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;;;;;GAMG;;AAEH;;GAEG;;AAEH;EACE,yBAAyB;AAC3B;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".starry-background {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  z-index: -1;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 100vh;\r\n  padding: 0 20px;\r\n}\r\n\r\n/* .card-out {\r\n  background-color: rgba(255, 255, 255, 0.1);\r\n  backdrop-filter: blur(10px);\r\n  border-radius: 15px;\r\n  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);\r\n  border: 1px solid rgba(255, 255, 255, 0.18);\r\n} */\r\n\r\n/* .card {\r\n  background-color: transparent;\r\n} */\r\n\r\nbody {\r\n  background-color: #000000;\r\n}\r\n\r\n.title,\r\n.title-state,\r\n.tap-bot {\r\n  color: #ffffff;\r\n}\r\n\r\n.title-bot {\r\n  color: #cccccc;\r\n}\r\n\r\n.ant-input {\r\n  background-color: rgba(255, 255, 255, 0.1);\r\n  border: 1px solid rgba(255, 255, 255, 0.2);\r\n  color: #ffffff;\r\n}\r\n\r\n.ant-input::placeholder {\r\n  color: rgba(255, 255, 255, 0.7);\r\n}\r\n\r\n.but {\r\n  background-color: #4ecca3;\r\n  color: #ffffff;\r\n}\r\n\r\n.but:hover {\r\n  background-color: #45b7a0 !important;\r\n}\r\n\r\n.tap-bot{\r\n  margin-left:120px;\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  color: white;\r\n}\r\n.tap-bot:hover {\r\n  color: white;\r\n  text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
