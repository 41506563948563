import React, { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { BlockMath, InlineMath } from 'react-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

function MessageList({ messages, theme }) {
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    const container = document.querySelector('.messages-container');
    if (!container) return;
    
    const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;
    
    if (isNearBottom) {
      messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <Box className="messages-container" sx={{
      bgcolor: theme.palette.background.messages,
      flexGrow: 1,
      overflow: 'auto',
      padding: '16px',
      px: 4,
    }}>
      {messages.map((message, index) => (
        <Box key={index} className={`message ${message.role}`} sx={{
          bgcolor: message.role === 'assistant' ? theme.palette.custom.assistantBg : theme.palette.custom.userBg,
          color: message.role === 'assistant' ? theme.palette.custom.assistantText : theme.palette.custom.userText,
          maxWidth: '100%',
          wordBreak: 'break-word',
        }}>
          <Typography component="div" className="typography">
            <ReactMarkdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex, rehypeRaw]}
              components={{
                code({node, inline, className, children, ...props}) {
                  const match = /language-(\w+)/.exec(className || '');
                  const codeString = String(children).replace(/\n$/, '');
                  
                  const handleCopy = () => {
                    navigator.clipboard.writeText(codeString)
                      .then(() => {
                        const notification = document.createElement('div');
                        notification.textContent = '代码已复制';
                        notification.style.cssText = `
                          position: fixed;
                          bottom: 20px;
                          left: 50%;
                          transform: translateX(-50%);
                          background: rgba(0, 0, 0, 0.7);
                          color: white;
                          padding: 8px 16px;
                          border-radius: 4px;
                          z-index: 1000;
                        `;
                        document.body.appendChild(notification);
                        setTimeout(() => notification.remove(), 2000);
                      })
                      .catch(err => console.error('复制失败:', err));
                  };

                  return !inline && match ? (
                    <div style={{ position: 'relative' }}>
                      <SyntaxHighlighter
                        language={match[1]}
                        style={vscDarkPlus}
                        showLineNumbers={true}
                        className="syntax-highlighter"
                        customStyle={{
                          margin: '1em 0',
                          padding: '1em',
                          backgroundColor: '#1e1e1e',
                          border: '1px solid #30363d',
                          borderRadius: '6px',
                          fontSize: '14px',
                        }}
                      >
                        {codeString}
                      </SyntaxHighlighter>
                      <button className="copy-button" onClick={handleCopy}>
                        复制
                      </button>
                    </div>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
                math: ({ value }) => {
                  try {
                    const processedValue = value
                      .replace(/\\int_/g, '\\int\\limits_')
                      .replace(/\\left\(/g, '(')
                      .replace(/\\right\)/g, ')')
                      .replace(/\\left\{/g, '{')
                      .replace(/\\right\}/g, '}')
                      .replace(/\\left\[/g, '[')
                      .replace(/\\right\]/g, ']')
                      .replace(/\\cdot/g, '·')
                      .replace(/\\_/g, '_')
                      .replace(/\\frac/g, '\\frac')
                      .trim();
                      
                    return (
                      <div style={{ overflow: 'auto', padding: '10px 0' }}>
                        <BlockMath math={processedValue} />
                      </div>
                    );
                  } catch (error) {
                    console.error('Math rendering error:', error);
                    return <code>{value}</code>;
                  }
                },
                inlineMath: ({ value }) => {
                  try {
                    const processedValue = value
                      .replace(/\\int_/g, '\\int\\limits_')
                      .replace(/\\left\[/g, '[')
                      .replace(/\\right\]/g, ']')
                      .replace(/\\cdot/g, '·')
                      .replace(/\\_/g, '_')
                      .replace(/\\frac/g, '\\frac')
                      .trim();
                      
                    const wrappedValue = processedValue.startsWith('$') ? processedValue : `$${processedValue}$`;
                    
                    return <InlineMath math={wrappedValue} />;
                  } catch (error) {
                    console.error('Math rendering error:', error);
                    return <code>{value}</code>;
                  }
                }
              }}
              >
                {message.content
                  .replace(/\n{2,}/g, '\n')
                  .replace(/\$([^$]+)\$/g, (_, formula) => {
                    return `$${formula}$`;
                  })
                  .replace(/\\\[/g, '$$')
                  .replace(/\\\]/g, '$$')
                  .replace(/\\\(/g, '$')
                  .replace(/\\\)/g, '$')
                }
              </ReactMarkdown>
            </Typography>
          </Box>
        ))}
        <div ref={messageEndRef} />
      </Box>
    );
  }
  
  export default MessageList;
