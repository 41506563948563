import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Box } from '@mui/material';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import Sidebar from '../components/Sidebar';
import MessageList from '../components/MessageList';
import InputArea from '../components/InputArea';
import HistoryDrawer from '../components/HistoryDrawer';
import ProfileDialog from '../components/ProfileDialog';
import './agent.css';

function Agent() {
  const navigate = useNavigate();
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [mode, setMode] = useState('light');
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('theme-mode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    
    if (!token || !user) {
      message.error('请先登录');
      navigate('/login');
      return;
    }

    loadConversations();
  }, [navigate]);

  const loadConversations = async () => {
    try {
      const response = await fetch('https://swarm.xixuer.cn/api/conversations', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('加载对话列表失败');
      }

      const data = await response.json();
      setConversations(data);
      
      if (data.length > 0) {
        loadConversation(data[0].id);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const loadConversation = async (conversationId) => {
    try {
      setCurrentConversationId(conversationId);
      setMessages([]);

      const response = await fetch(`https://swarm.xixuer.cn/api/conversations/${conversationId}/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('加载对话失败');
      }

      const messages = await response.json();
      setMessages(messages.map(msg => ({
        role: msg.role,
        content: msg.content
      })));
    } catch (error) {
      message.error(error.message);
    }
  };

  const deleteConversation = async (conversationId) => {
    try {
      const response = await fetch(`https://swarm.xixuer.cn/api/conversations/${conversationId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('删除对话失败');
      }

      if (conversationId === currentConversationId) {
        setCurrentConversationId(null);
        setMessages([]);
      }

      await loadConversations();
      message.success('删除成功');
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleNewConversation = async () => {
    try {
      const response = await fetch('https://swarm.xixuer.cn/api/conversations/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('创建对话失败');
      }

      const result = await response.json();
      setCurrentConversationId(result.conversation_id);
      setMessages([]);
      const newConvMessage = { role: 'assistant', content: '新的对话开始了。' };
      setMessages([newConvMessage]);

      await loadConversations();

    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSend = async (input) => {
    try {
      const userMessage = { role: 'user', content: input };
      setMessages(prev => [...prev, userMessage]);

      if (!currentConversationId) {
        await handleNewConversation();
      }

      const response = await fetch('https://swarm.xixuer.cn/api/chat/stream', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        credentials: 'include',
        body: JSON.stringify({
          input: input,
          conversation_id: currentConversationId
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          message.error('登录已过期，请重新登录');
          navigate('/login');
          return;
        }
        throw new Error('发送消息失败');
      }

      const aiMessage = { role: 'assistant', content: '' };
      setMessages(prev => [...prev, aiMessage]);

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let accumulatedContent = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.content) {
                accumulatedContent += data.content;
                setMessages(prev => {
                  const newMessages = [...prev];
                  const lastMessage = newMessages[newMessages.length - 1];
                  lastMessage.content = accumulatedContent;
                  return newMessages;
                });
              }
            } catch (error) {
              console.error('解析响应数据失败:', error);
            }
          }
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('theme-mode', newMode);
  };

  const theme = createTheme({
    palette: {
      mode,
      ...(mode === 'dark' ? {
        background: {
          default: '#121212',
          paper: '#1e1e1e',
          messages: '#1e1e1e'
        },
        custom: {
          assistantBg: '#2d2d2d',
          assistantText: '#ffffff',
          userBg: '#4CAF50',
          userText: '#ffffff',
          inputBg: '#1e1e1e',
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      } : {
        background: {
          default: '#fff',
          paper: '#fff',
          messages: '#f5f5f5'  // Light grey background for messages
        },
        custom: {
          assistantBg: '#f0f0f0',
          assistantText: 'rgba(0, 0, 0, 0.87)',
          userBg: '#4CAF50',
          userText: '#ffffff',
          inputBg: '#ffffff',
          borderColor: '#eeeeee'
        }
      })
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        display: 'flex', 
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        padding: '0'
      }}>
        <Sidebar 
          onNewConversation={handleNewConversation}
          onToggleHistory={() => setHistoryDrawerOpen(!historyDrawerOpen)}
          onToggleTheme={toggleTheme}
          onOpenProfile={() => setProfileOpen(true)}
          theme={theme}
          recentConversations={conversations}
          onSelect={loadConversation}
        />
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column',
          bgcolor: theme.palette.background.default,
          overflow: 'hidden',
          px: 4,
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            px: 4,
          }}>
            <Box sx={{
              width: '100%',
              maxWidth: '800px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}>
              <MessageList messages={messages} theme={theme} />
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: '16px',
                backgroundColor: theme.palette.background.default
              }}>
                <InputArea onSend={handleSend} theme={theme} />
              </Box>
            </Box>
          </Box>
        </Box>
        <HistoryDrawer
          open={historyDrawerOpen}
          onClose={() => setHistoryDrawerOpen(false)}
          conversations={conversations}
          onSelect={loadConversation}
          onDelete={deleteConversation}
          theme={theme}
        />
        <ProfileDialog open={profileOpen} onClose={() => setProfileOpen(false)} />
      </Box>
    </ThemeProvider>
  );
}

export default Agent;