import React, {useEffect, useState, useMemo} from 'react'
import {message} from 'antd';
import{ useNavigate, useLocation } from 'react-router-dom';
import './index.css'
// 工具函数，用于检查用户是否登录
const isUserLoggedIn = () => {
  return !!localStorage.getItem('token');
};

// 工具函数，用于解析token并获取用户信息
const parseToken = (token) => {
  // 这里应该有解析token并返回用户信息的逻辑
  // 例如：
  return {
    name: 'John Doe',
    avatar: 'path/to/avatar.jpg'
  };
};
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径
  // 用户信息状态
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());
  // 工具函数，用于退出登录
  const logout = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    console.log(userInfo);
    console.log('用户', userInfo.username, '已退出登录');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
    message.success('退出账号成功！', 2);
  };
   // 在Component函数开头添加状态
   const [showDropdown, setShowDropdown] = useState(false);

   // 添加点击外部关闭下拉菜单的效果
   useEffect(() => {
     const handleClickOutside = (event) => {
       if (showDropdown && !event.target.closest('.user-dropdown')) {
         setShowDropdown(false);
       }
     };
 
     document.addEventListener('click', handleClickOutside);
     return () => {
       document.removeEventListener('click', handleClickOutside);
     };
   }, [showDropdown]);
  // 检查登录状态变化
  useEffect(() => {
    const handleStorageChange = () => {
      const newLoggedInState = isUserLoggedIn();
      if (newLoggedInState !== isLoggedIn) {
        setIsLoggedIn(newLoggedInState);
        if (newLoggedInState) {
          const userData = parseToken(localStorage.getItem('token'));
          setUser(userData);
        } else {
          setUser(null);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLoggedIn]);
  // 根据登录状态渲染不同的组件
  const renderUserManagement = () => {
    if (isLoggedIn) {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      return (
        <div className="user-dropdown">
          <button className="user-button" onClick={() => setShowDropdown(!showDropdown)}>
            {`用户${userInfo?.username || 'User'}`}
          </button>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={logout}>
                退出登录
              </button>
            </div>
          )}
        </div>
      );
    }
    return (
      <button className="login-button" onClick={() => navigate('/login')}>
        Sign In
      </button>
    );
  };
  return (
    <div className="page-main">
      <nav className="top">
        <a href="/" className="logo">
          SurveySage
        </a>
        <div className='nav-tag'>
          <a href="/product-intro" className={`nav-f ${location.pathname === '/product-intro' ? 'active' : ''}`}>
            产品介绍
          </a>
          <a href="/customer-support" className={`nav-s ${location.pathname === '/customer-support' ? 'active' : ''}`}>
            客户支持
          </a>
          <a href="/about-us" className={`nav-a ${location.pathname === '/about-us' ? 'active' : ''}`}>
            关于我们
          </a>
        </div>
        <div className="user-management">
          {renderUserManagement()}
        </div>
      </nav>
      <div className="privacy-policy">
        <div className='privacy-content'>
          <h1 className="privacy-title">隐私政策</h1>
          <p>最后更新日期：2024年11月15日</p>
          <p>欢迎使用 SurveySage 广益（以下简称“本平台”）。我们非常重视您的隐私，并致力于保护您的个人信息。请您仔细阅读本隐私政策，以了解我们如何收集、使用、存储和保护您的信息。</p>

          <h2>1. 信息的收集</h2>
          <p>我们可能会收集以下类型的信息：</p>
          <ul>
            <li>个人信息：如姓名、电子邮件地址、电话号码等。</li>
            <li>使用数据：包括您访问本平台的时间、频率、浏览的页面等信息。</li>
            <li>设备信息：如设备类型、操作系统、IP 地址等。</li>
          </ul>

          <h2>2. 信息的使用</h2>
          <p>我们收集的信息将用于以下目的：</p>
          <ul>
            <li>提供和维护我们的服务。</li>
            <li>通知您有关我们服务的变更。</li>
            <li>允许您参与互动功能。</li>
            <li>提供客户支持。</li>
            <li>收集分析数据，以改进我们的服务。</li>
            <li>监控服务的使用情况。</li>
            <li>检测、预防和解决技术问题。</li>
          </ul>

          <h2>3. 信息的存储</h2>
          <p>我们将您的个人信息存储在安全的服务器上，并采取合理的技术和管理措施来保护您的信息不被未经授权的访问、使用或披露。我们将根据法律要求和业务需要保留您的信息。</p>

          <h2>4. 信息的分享</h2>
          <p>我们不会将您的个人信息出售或出租给第三方。我们可能会在以下情况下分享您的信息：</p>
          <ul>
            <li>与我们的服务提供商共享，以便他们为我们提供服务。</li>
            <li>在法律要求的情况下，或为了保护我们的权利、隐私、安全或财产。</li>
            <li>在合并、收购或资产出售的情况下，您的信息可能会作为交易的一部分被转移。</li>
          </ul>

          <h2>5. 您的权利</h2>
          <p>您有权访问、更正或删除我们收集的您的个人信息。您可以随时联系我们以行使这些权利。</p>

          <h2>6. 儿童隐私</h2>
          <p>本平台不针对13岁以下儿童收集个人信息。如果我们发现我们无意中收集了13岁以下儿童的个人信息，我们将采取措施尽快删除该信息。</p>

          <h2>7. 隐私政策的变更</h2>
          <p>我们可能会不时更新本隐私政策。我们会在本页面上发布更新，并在必要时通过电子邮件通知您。请定期查看本隐私政策以了解任何更改。</p>

          <h2>8. 联系我们</h2>
          <p>如果您对本隐私政策有任何疑问或意见，请通过以下方式与我们联系：</p>
          <p>📧 邮箱: support@surveySage.com</p>
        </div>
      </div>
      <footer className="footer">
        <div className="p-footer">
          <div className='footer-left'>
            <h3 className='footer-title'>SurveySage广益</h3>
            <h3 className='footer-subtitle'>智能问卷调查的未来</h3>
          </div>
          <div className='footer-right'>
            <a href="/privacy-policy" className="nav-a">隐私政策</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;