import React, { useRef, useEffect, useState, useMemo} from 'react'
import {message} from 'antd';
import{ useNavigate, Link, useLocation } from 'react-router-dom';
import './index.css'
// 工具函数，用于检查用户是否登录
const isUserLoggedIn = () => {
  return !!localStorage.getItem('token');
};

// 工具函数，用于解析token并获取用户信息
const parseToken = (token) => {
  // 这里应该有解析token并返回用户信息的逻辑
  // 例如：
  return {
    name: 'John Doe',
    avatar: 'path/to/avatar.jpg'
  };
};
const ProductIntro = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 获取当前路径
  // 用户信息状态
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());
  // 工具函数，用于退出登录
  const logout = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    console.log(userInfo);
    console.log('用户', userInfo.username, '已退出登录');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
    message.success('退出账号成功！', 2);
  };
   // 在Component函数开头添加状态
   const [showDropdown, setShowDropdown] = useState(false);

   // 添加点击外部关闭下拉菜单的效果
   useEffect(() => {
     const handleClickOutside = (event) => {
       if (showDropdown && !event.target.closest('.user-dropdown')) {
         setShowDropdown(false);
       }
     };
 
     document.addEventListener('click', handleClickOutside);
     return () => {
       document.removeEventListener('click', handleClickOutside);
     };
   }, [showDropdown]);
  // 检查登录状态变化
  useEffect(() => {
    const handleStorageChange = () => {
      const newLoggedInState = isUserLoggedIn();
      if (newLoggedInState !== isLoggedIn) {
        setIsLoggedIn(newLoggedInState);
        if (newLoggedInState) {
          const userData = parseToken(localStorage.getItem('token'));
          setUser(userData);
        } else {
          setUser(null);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLoggedIn]);
  // 根据登录状态渲染不同的组件
  const renderUserManagement = () => {
    if (isLoggedIn) {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      return (
        <div className="user-dropdown">
          <button className="user-button" onClick={() => setShowDropdown(!showDropdown)}>
            {`用户${userInfo?.username || 'User'}`}
          </button>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={logout}>
                退出登录
              </button>
            </div>
          )}
        </div>
      );
    }
    return (
      <button className="login-button" onClick={() => navigate('/login')}>
        Sign In
      </button>
    );
  };
  return (
    <div className="page-main">
      {/* Navigation */}
      <nav className="top">
        <a href="/" className="logo">
          SurveySage
        </a>
        <div className='nav-tag'>
          <a href="/product-intro" className={`nav-f ${location.pathname === '/product-intro' ? 'active' : ''}`}>
            产品介绍
          </a>
          <a href="/customer-support" className={`nav-s ${location.pathname === '/customer-support' ? 'active' : ''}`}>
            客户支持
          </a>
          <a href="/about-us" className={`nav-a ${location.pathname === '/about-us' ? 'active' : ''}`}>
            关于我们
          </a>
        </div>
        <div className="user-management">
          {renderUserManagement()}
        </div>
      </nav>
      {/* Content */}
      <div className="product-intro">
        <div className="product-intro-content">
          <h1 className='product-intro-title'>产品介绍</h1>
          <p className='product-intro-text'>
            SurveySage是一个智能问卷调查和数据分析平台，利用自然语言处理（NLP）和人工智能（AI）技术，提供自动化问卷生成、数据采集、分析和可视化等功能。
          </p>
          <p className='product-intro-text'>
            平台的核心功能包括智能问卷设计、调查受众自动分类、数据清洗、深入的情感分析、以及通过图表和报告形式展示结果。
          </p>
          <section className="features-section">
            <h2>核心功能</h2>
            <ul>
              <li>智能问卷设计：根据用户需求自动生成问卷。</li>
              <li>数据分析：实时分析收集的数据，提供深度洞察。</li>
              <li>多种图表展示：通过多种图表形式展示数据结果。</li>
              <li>用户友好的界面：简洁直观的用户界面，易于操作。</li>
            </ul>
          </section>
          <section className="testimonials-section">
            <h2>用户反馈</h2>
            <blockquote>
              <p>“SurveySage极大地提高了我们的数据收集效率，分析结果也非常准确！”</p>
              <footer>— 用户A</footer>
            </blockquote>
            <blockquote>
              <p>“使用SurveySage后，我们的问卷完成率提升了80%！”</p>
              <footer>— 用户B</footer>
            </blockquote>
          </section>
        </div>
      </div>
      <footer className="footer">
        <div className="p-footer">
          <div className='footer-left'>
            <h3 className='footer-title'>SurveySage广益</h3>
            <h3 className='footer-subtitle'>智能问卷调查的未来</h3>
          </div>
          <div className='footer-right'>
            <a href="/privacy-policy" className="nav-a">
              隐私政策
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default ProductIntro;