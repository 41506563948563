// 首页
import React, { useRef, useEffect, useState, useMemo} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {Button, message} from 'antd';
import { BarChart, PieChart,Brain,Plus } from 'lucide-react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, Text, Stars } from '@react-three/drei'
import * as THREE from 'three'
import{ useNavigate, Link } from 'react-router-dom';
import './index.css'
// 数据点(点的位置和显示、鼠标悬停效果、点的动态移动（当其他点被悬停时）、显示数据信息)
const DataPoint = ({ position, data, hoveredPoint, setHoveredPoint }) => {
  const [hovered, setHovered] = useState(false)
  const { camera } = useThree()
  const meshRef = useRef()
  const originalPosition = useRef(new THREE.Vector3(...position))

  useFrame(() => {
    if (!meshRef.current) return

    const targetPosition = originalPosition.current.clone()
    if (hoveredPoint && hoveredPoint !== meshRef.current) {
      const direction = targetPosition.clone().sub(new THREE.Vector3(...hoveredPoint.position))
      direction.normalize().multiplyScalar(0.5)
      targetPosition.add(direction)
    }

    meshRef.current.position.lerp(targetPosition, 0.1)
  })

  return (
    <group>
      <mesh
        ref={meshRef}
        position={position}
        onPointerOver={() => {
          setHovered(true)
          setHoveredPoint(meshRef.current)
        }}
        onPointerOut={() => {
          setHovered(false)
          setHoveredPoint(null)
        }}
      >
        {/* 球的信息 */}
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshStandardMaterial color={hovered ? "#fff" : "#47CFE5"} />
      </mesh>
      {hovered && (
        <Text
          position={[meshRef.current.position.x, meshRef.current.position.y + 0.1, meshRef.current.position.z]}
          fontSize={0.4}
          color="white"
          anchorX="center"
          anchorY="middle"
          lookAt={camera.position}
        >
          {`${data.name}: ${data.value}`}
        </Text>
      )}
    </group>
  )
}
// 交互球体(生成所有数据点，管理被悬停的点状态)
const InteractiveSphere = () => {
  const [hoveredPoint, setHoveredPoint] = useState(null)

  const dataPoints = useMemo(() => {
    const points = []
    const totalPoints = 1000
    for (let i = 0; i < totalPoints; i++) {
      const phi = Math.acos(-1 + (2 * i) / totalPoints)
      const theta = Math.sqrt(totalPoints * Math.PI) * phi

      const x = 7 * Math.cos(theta) * Math.sin(phi)
      const y = 7 * Math.sin(theta) * Math.sin(phi)
      const z = 7 * Math.cos(phi)

      points.push({
        position: [x, y, z],
        data: {
          name: `Point ${i}`,
          value: Math.floor(Math.random() * 100)
        }
      })
    }
    return points
  }, [])

  return (
    <group>
      {dataPoints.map((point, index) => (
        <DataPoint
          key={index}
          position={point.position}
          data={point.data}
          hoveredPoint={hoveredPoint}
          setHoveredPoint={setHoveredPoint}
        />
      ))}
    </group>
  )
}
// 特色
const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="f-card"
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.05 }}
  >
    {/* <Icon className="card-icon" /> */}
    <h3 className="card-title">{title}</h3>
    <p className="card-description">{description}</p>
  </motion.div>
)

// 工具函数，用于检查用户是否登录
const isUserLoggedIn = () => {
  return !!localStorage.getItem('token');
};

// 工具函数，用于解析token并获取用户信息
const parseToken = (token) => {
  // 这里应该有解析token并返回用户信息的逻辑
  // 例如：
  return {
    name: 'John Doe',
    avatar: 'path/to/avatar.jpg'
  };
};
export default function Component() {
  const [selectedOption, setSelectedOption] = useState('buy');
  const parallaxRef = useRef(null)
  const [parallaxY, setParallaxY] = useState(0)
  const navigate = useNavigate();
  // 用户信息状态
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());
  // 工具函数，用于退出登录
  const logout = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    console.log(userInfo);
    console.log('用户', userInfo.username, '已退出登录');
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
    message.success('退出账号成功！', 2);
  };
  // 检查登录状态变化
  useEffect(() => {
    const handleStorageChange = () => {
      const newLoggedInState = isUserLoggedIn();
      if (newLoggedInState !== isLoggedIn) {
        setIsLoggedIn(newLoggedInState);
        if (newLoggedInState) {
          const userData = parseToken(localStorage.getItem('token'));
          setUser(userData);
        } else {
          setUser(null);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLoggedIn]);
  // 根据登录状态渲染不同的组件
  const renderUserManagement = () => {
    if (isLoggedIn) {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      return (
        <div className="user-dropdown">
          <button className="user-button" onClick={() => setShowDropdown(!showDropdown)}>
            {`用户${userInfo?.username || 'User'}`}
          </button>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={logout}>
                退出登录
              </button>
            </div>
          )}
        </div>
      );
    }
    return (
      <button className="login-button" onClick={() => navigate('/login')}>
        Sign In
      </button>
    );
  };
  // const handleLoginClick = () => {
  //   navigate('/login');
  // };
  const handleAiClick = () => {
    navigate('/agent');
  }
  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollPosition = window.pageYOffset
        setParallaxY(scrollPosition * 0.5) // Adjust the multiplier for faster/slower parallax
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  // 订阅计划
  const plans = [
    {
      id: 'basic',
      title: '基础版',
      price: '¥99/月',
      features: [
        '每月最多100份问卷',
        '基础AI问卷设计',
        '标准数据分析报告',
        '电子邮件支持'
      ]
    },
    {
      id: 'professional',
      title: '专业版',
      price: '¥299/月',
      features: [
        '每月无限问卷',
        '高级AI问卷设计',
        '深度数据分析和可视化',
        '优先电话和邮件支持',
        '自定义品牌和报告'
      ]
    },
    {
      id: 'enterprise',
      title: '企业版',
      price: '联系我们',
      features: [
        '完全定制化解决方案',
        '专属客户经理',
        '高级API集成',
        '现场培训和支持',
        '企业级安全和合规'
      ]
    }
  ];
  // 常见问题
  const [expandedQuestion, setExpandedQuestion] = useState('');
  const questions = [
    {
      id: 'ai',
      question: 'SurveySage如何使用AI技术？',
      answer: 'SurveySage利用先进的自然语言处理和机器学习算法来设计问卷、分析回答，并生成见解。我们的AI可以理解上下文，提供个性化的问题建议，并从自由文本回答中提取关键主题和情感。'
    },
    {
      id: 'data',
      question: '数据安全和隐私如何保障？',
      answer: '我们采用银行级别的加密技术保护数据传输和存储，符合最新的数据隐私标准。系统具有完善的身份认证机制，确保99.9%的系统稳定性和数据安全性。所有数据都进行严格的访问控制和审计跟踪。'
    },
    {
      id: 'integration',
      question: '是否支持与现有系统集成？',
      answer: 'SurveySage提供完整的RESTful API接口，支持与主流CRM、ERP系统无缝集成。平台可以与至少3个主流数据分析工具兼容，并支持数据的导入导出。'
    },
    {
      id: 'efficiency',
      question: '使用SurveySage能带来哪些效率提升？',
      answer: '相比传统方式，我们的平台可以节省50%的数据采集时间，1分钟内清洗1000条数据记录，2分钟内生成可视化报告。通过智能化设计，问卷完成率可提升至80%以上。'
    },
    {
      id: 'analysis',
      question: '数据分析的准确度如何？',
      answer: '我们的情感分析和数据洞察准确率达90%以上。系统采用机器学习算法进行数据挖掘，可以快速识别关键趋势和模式，生成深度分析报告。'
    }
  ];
  const handleSelectQuestion = (questionId) => {
    setExpandedQuestion(expandedQuestion === questionId ? '' : questionId);
  };

  // options
  const options = [
    {
      id: 'buy',
      title: '订阅',
      content: (
        <div className="subscription-plans">
          {plans.map((plan) => (
            <div key={plan.id} className="plan">
              <h3 className="plan-title">{plan.title}</h3>
              <p className="plan-price">{plan.price}</p>
              <ul className="plan-features">
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <span className="checkmark">✔️</span> {feature}
                  </li>
                ))}
              </ul>
              <button className="plan-select">选择方案</button>
            </div>
          ))}
        </div>
      )
    },
    {
      id: 'question',
      title: '常见问题',
      content: (
        <div className="ques-container">
          <div className="ques-list">
            {questions.map((q) => (
              <div key={q.id} className="ques-item" onClick={() => handleSelectQuestion(q.id)}>
                <h3 className="ques-question">{q.question}</h3>
                {expandedQuestion === q.id && <p className="ques-answer">{q.answer}</p>}
                <span className={`ques-arrow ${expandedQuestion === q.id ? 'expanded' : ''}`} />
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      id: 'function',
      title: '基本功能',
      content: (
        <div className="fun-container">
          <div className="fun-grid">
            <div className="fun-item">
              <h3 className="fun-title">智能问卷设计</h3>
              <ul className="fun-list">
                <li>AI辅助问卷生成（95%准确率）</li>
                <li>智能题型推荐与优化</li>
                <li>多语言问卷支持</li>
                <li>个性化调查模板</li>
              </ul>
            </div>
            
            <div className="fun-item">
              <h3 className="fun-title">数据采集与处理</h3>
              <ul className="fun-list">
                <li>高效数据采集（节省50%时间）</li>
                <li>实时数据清洗</li>
                <li>异常检测与处理</li>
                <li>自动化数据验证</li>
              </ul>
            </div>
            
            <div className="fun-item">
              <h3 className="fun-title">智能分析与可视化</h3>
              <ul className="fun-list">
                <li>90%+准确率的情感分析</li>
                <li>深度数据挖掘</li>
                <li>动态图表生成</li>
                <li>交互式数据仪表板</li>
              </ul>
            </div>
            
            <div className="fun-item">
              <h3 className="fun-title">系统集成与安全</h3>
              <ul className="fun-list">
                <li>多平台API集成</li>
                <li>99.9%系统稳定性</li>
                <li>银行级数据加密</li>
                <li>完整的权限管理</li>
              </ul>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'support',
      title: '技术支持',
      content: (
        <div className="sup-container">
          <div className="sup-grid">
            <div className="sup-card">
              <h3 className="sup-title">专业技术咨询</h3>
              <div className="sup-content">
                <p>AI算法专家在线支持</p>
                <p>数据分析技术指导</p>
                <p>系统集成咨询</p>
                <button className="sup-button">立即咨询</button>
              </div>
            </div>

            <div className="sup-card">
              <h3 className="sup-title">开发者资源</h3>
              <div className="sup-content">
                <ul className="sup-list">
                  <li>API文档中心</li>
                  <li>SDK开发工具包</li>
                  <li>示例代码库</li>
                  <li>技术白皮书</li>
                </ul>
              </div>
            </div>

            <div className="sup-card">
              <h3 className="sup-title">培训与认证</h3>
              <div className="sup-content">
                <p>平台使用培训</p>
                <p>数据分析认证</p>
                <p>最佳实践指南</p>
                <p>案例研究分享</p>
              </div>
            </div>

            <div className="sup-card">
              <h3 className="sup-title">服务保障</h3>
              <div className="sup-content">
                <p>7*24小时技术支持</p>
                <p>99.9%系统可用性</p>
                <p>定期系统维护</p>
                <p>数据备份服务</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  ]
  // 在Component函数开头添加状态
  const [showDropdown, setShowDropdown] = useState(false);

  // 添加点击外部关闭下拉菜单的效果
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && !event.target.closest('.user-dropdown')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="page-main">
      {/* Navigation */}
      <nav className="top">
        <Link href="/" className="logo">
          SurveySage
        </Link>
        <div className='nav-tag'>
          <a href="/product-intro" className="nav-f">
            产品介绍
          </a>
          <a href="/customer-support" className="nav-s">
            客户支持
          </a>
          <a href="/about-us" className="nav-a">
            关于我们
          </a>
        </div>
        <div className="user-management">
          {renderUserManagement()}
        </div>
      </nav>
      <main>
        {/* 大屏 */}
        <section className="top-text">
          <p className='p-title'>
            Welcome to SurveySage
            <br/>
            <p className='p-tag'>
              智能问卷调查和数据分析平台
            </p>
          </p>
          <p className="description">
          SurveySage平台的核心功能包括智能问卷设计、调查受众自动分类、数据清洗、深入的情感分析、以及通过图表和报告形式展示结果。
          </p>
          <div className="goto">
            <button className="btn-ai"
            onClick={handleAiClick}>
              Start Using AI
            </button>
          </div>
          <p className="p-bot">
            Start a free trial.
          </p>
        </section>
        {/* 特 */}
        <section id="features" className="style-features">
          <div className="f-main">
              <motion.h2
                className="f-title"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Our Features
              </motion.h2>
              <div className="f-cards">
                <FeatureCard
                  className="f-card"
                  // icon={BarChart}
                  title="智能问卷设计"
                  description="利用NLP技术，根据用户输入的主题自动生成问卷;
                  确保问卷的语义清晰，避免歧义，提高问卷质量;
                  根据用户需求和受众特征，提供定制化的问卷设计建议。"
                />
                <FeatureCard
                  className="f-card"
                  // icon={Brain}
                  title=" 数据驱动的洞察 "
                  description="通过AI技术对问卷数据进行情感分析，洞察用户情绪倾向;识别问卷数据中的关键词汇，快速把握用户关注点;将复杂的数据以图表和报告的形式直观展示，便于理解。"
                />
                <FeatureCard
                  className="f-card"
                  // icon={PieChart}
                  title="高效的数据采集与处理"
                  description="自动化工具减少人工干预，提高数据采集效率;AI算法快速清洗数据，确保数据质量;机器学习模型识别并处理数据中的异常值。"
                />
                <FeatureCard
                  className="f-card"
                  // icon={PieChart}
                  title="多行业适用性"
                  description="适用于商业、教育、医疗、社会科学等多个领域;根据不同行业的特点，提供定制化的问卷调查解决方案。"
                />
              </div>
            </div>
        </section>
        {/* 3d球展区 */}
        <section className="ball-screen">
          <div className="star-field">
            <motion.h2
                className="ball-title"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                数据展区
              </motion.h2>
            {/* 相机的视角和视场 */}
            <div className='screen-container'>
              <Canvas camera={{ position: [0, 0, 40], fov: 30 }}>
                <ambientLight intensity={0.5} />
                <pointLight position={[10, 10, 10]} />
                <Stars radius={100} depth={50} count={6000} factor={8} saturation={0} fade speed={1} />
                <InteractiveSphere/>
                <OrbitControls enableZoom={false} enablePan={true} />
              </Canvas>
            </div>
          </div>
        </section>
        {/* 多功能框 */}
        <section className='mult-function'>
          <div className="mult-main">
            <div className="mult-container">
              {/* Left Panel - Options */}
              <div className="left-panel">
                {options.map((option) => (
                  <div key={option.id} className="left-list">
                    <Button
                      type="ghost"
                      className={`list-item ${
                        selectedOption === option.id ? 'selected' : ''}`}
                      onClick={() => setSelectedOption(option.id)}
                    >
                      <div className="list-content">
                        <span className="list-title">{option.title}</span>
                        <Plus className="list-icon" />
                      </div>
                    </Button>
                    {option.subtitle && (
                      <p className="alert">{option.subtitle}</p>
                    )}
                  </div>
                ))}
              </div>

              {/* Right Panel - Content */}
              <div className="right-panel">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={selectedOption}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                    className="absolute inset-0"
                  >
                    <div className="relative z-10">
                      {options.find(opt => opt.id === selectedOption)?.content}
                    </div>
                    {selectedOption === 'fund' && (
                      <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-blue-500/20 blur-3xl -z-10" />
                    )}
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </section>
        <section className='get-started'>
          <div className="get-started-container">
            <motion.h2
              className="get-started-title"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              准备好开始了吗？
            </motion.h2>
            <p className='get-started-description'>
              立即体验 SurveySage，开启智能问卷调查之旅
            </p>
            <div className="get-started-actions">
              <button 
                className="primary-btn"
                onClick={() => navigate('/login')}
              >
                免费注册
              </button>
              <button 
                className="secondary-btn"
                onClick={() => navigate('/agent')}
              >
                开始试用
              </button>
            </div>
            <div className="get-started-features">
              <div className="feature-item">
                ✨ 14天免费试用
              </div>
              <div className="feature-item">
                🛡️ 无需信用卡
              </div>
              <div className="feature-item">
                🚀 即刻开始使用
              </div>
            </div>
          </div>
        </section>
        {/* 联系 */}
        <section className='contact'>
          <div className="contact-container"> 
            <div className="box-of-star1">
                <div className="star star-position1"></div>
                <div className="star star-position2"></div>
                <div className="star star-position3"></div>
                <div className="star star-position4"></div>
                <div className="star star-position5"></div>
                <div className="star star-position6"></div>
                <div className="star star-position7"></div>
              </div>
              <div className="box-of-star2">
                <div className="star star-position1"></div>
                <div className="star star-position2"></div>
                <div className="star star-position3"></div>
                <div className="star star-position4"></div>
                <div className="star star-position5"></div>
                <div className="star star-position6"></div>
                <div className="star star-position7"></div>
              </div>
              <div className="box-of-star3">
                <div className="star star-position1"></div>
                <div className="star star-position2"></div>
                <div className="star star-position3"></div>
                <div className="star star-position4"></div>
                <div className="star star-position5"></div>
                <div className="star star-position6"></div>
                <div className="star star-position7"></div>
              </div>
              <div className="box-of-star4">
                <div className="star star-position1"></div>
                <div className="star star-position2"></div>
                <div className="star star-position3"></div>
                <div className="star star-position4"></div>
                <div className="star star-position5"></div>
                <div className="star star-position6"></div>
                <div className="star star-position7"></div>
              </div>
              <div data-js="astro" className="astronaut">
                <div className="head"></div>
                <div className="arm arm-left"></div>
                <div className="arm arm-right"></div>
                <div className="body">
                  <div className="panel"></div>
                </div>
                <div className="leg leg-left"></div>
                <div className="leg leg-right"></div>
                <div className="schoolbag"></div>
              </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="p-footer">
          <div className='footer-left'>
            <h3 className='footer-title'>SurveySage广益</h3>
            <h3 className='footer-subtitle'>智能问卷调查的未来</h3>
          </div>
          <div className='footer-right'>
            <a href="/privacy-policy" className="nav-a">
              隐私政策
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}