import React, { useEffect, useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box 
} from '@mui/material';

function ProfileDialog({ open, onClose }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const formatDateTime = (isoString) => {
    if (!isoString) return '从未对话';
    try {
      // 添加调试日志
      console.log('原始时间字符串:', isoString);
      
      const date = new Date(isoString);
      console.log('解析后的Date对象:', date);
      
      // 检查是否是有效的日期
      if (isNaN(date.getTime())) {
        console.error('无效的日期格式');
        return '时间格式错误';
      }

      return date.toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    } catch (err) {
      console.error('时间格式化错误:', err);
      return '时间格式化错误';
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://swarm.xixuer.cn/api/user/info', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('获取用户信息失败');
        }

        const data = await response.json();
        console.log('获取到的用户数据:', data); // 添加调试日志
        setUser(data.user);
      } catch (error) {
        console.error('获取用户信息错误:', error);
        setError(error.message);
      }
    };

    if (open) {
      fetchUserInfo();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>个人信息</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          {error && (
            <Typography color="error">
              错误: {error}
            </Typography>
          )}
          {user ? (
            <>
              <Typography variant="subtitle1">
                用户名: {user.username}
              </Typography>
              <Typography variant="subtitle1">
                邮箱: {user.email}
              </Typography>
              <Typography variant="subtitle1">
                注册时间: {formatDateTime(user.created_at)}
              </Typography>
              <Typography variant="subtitle1">
                用户ID: {user.id}
              </Typography>
              <Typography variant="subtitle1">
                对话总数: {user.stats.conversation_count}
              </Typography>
              <Typography variant="subtitle1">
                消息总数: {user.stats.message_count}
              </Typography>
              <Typography variant="subtitle1">
                最后一次对话时间: {formatDateTime(user.stats.last_chat_time)}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle1">加载中...</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>关闭</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileDialog;
