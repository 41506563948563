// App.js 或其他路由配置文件
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './pages/login';
import ForgotPassword from './pages/forget';
import Index from './pages/index';
import ProductIntro from './pages/ProductIntro'; // 产品介绍页面组件
import CustomerSupport from './pages/CustomerSupport'; // 客户支持页面组件
import AboutUs from './pages/AboutUs'; // 关于我们页面组件
import PrivacyPolicy from './pages/PrivacyPolicy'; // 隐私政策页面组件
import Agent from './pages/agent';
const App = () => {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/product-intro" element={<ProductIntro />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/forget" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
        </Routes>
    </Router>
  );
}

export default App;