import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { AttachFile as AttachFileIcon, Send as SendIcon } from '@mui/icons-material';

function InputArea({ onSend, theme }) {
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      onSend(input);
      setInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Box className="input-container" sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      px: 4,
    }}>
      <input
        type="file"
        onChange={() => {}}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <IconButton component="span">
          <AttachFileIcon />
        </IconButton>
      </label>
      <TextField
        multiline
        maxRows={3}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="输入消息..."
        sx={{
          flex: 1,
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.custom.inputBg,
            color: theme.palette.text.primary,
            '& fieldset': {
              borderColor: theme.palette.custom.borderColor,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
            }
          }
        }}
      />
      <IconButton color="primary" onClick={handleSend}>
        <SendIcon />
      </IconButton>
    </Box>
  );
}

export default InputArea;