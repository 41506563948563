import { useState, useEffect } from "react";
import { Button, Input, Card, Checkbox, Form,message } from "antd";
import './login.css';
import { initStarryBackground } from './starry-background';

const Login = () => {
  useEffect(() => {
    initStarryBackground();
  }, []);
  const [form] = Form.useForm();  
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isLogin, setIsLogin] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const [captcha, setCaptcha] = useState('');

  const loadCaptcha = async () => {
    try {
      const response = await fetch('https://swarm.xixuer.cn/api/captcha', {
        headers: {
          'Accept': 'image/jpeg',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        mode: 'cors'
      });
      
      if (!response.ok) {
        throw new Error('加载验证码失败');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCaptcha(url);
      
      // 清理之前的 URL
      if (captcha) {
        URL.revokeObjectURL(captcha);
      }
    } catch (error) {
      console.error('加载验证码错误:', error);
      message.error('加载验证码失败，请刷新重试');
    }
  };

  useEffect(() => {
    loadCaptcha();
    
    // 组件卸载时清理最后一个验证码 URL
    return () => {
      if (captcha) {
        URL.revokeObjectURL(captcha);
      }
    };
  }, []);

  const handleLogin = async (values) => {
    try {
      setLoading(true);
      
      const captchaResponse = await fetch('https://swarm.xixuer.cn/api/verify-captcha', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ 
          captcha: values.captcha 
        })
      });

      if (!captchaResponse.ok) {
        const captchaData = await captchaResponse.json();
        throw new Error(captchaData.error || '验证码错误');
      }

      const response = await fetch('https://swarm.xixuer.cn/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: values.usernameOrEmail,
          password: values.password
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '登录失败');
      }

      // 登录成功
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      message.success(`登录成功！欢迎 ${data.user.username}`);
      
      // 延迟跳转
      setTimeout(() => {
        window.location.href = '/';
      }, 1500);

    } catch (error) {
      message.error(error.message);
      loadCaptcha();
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (values) => {
    try {
      setLoading(true);
      
      // 先验证验证码
      const captchaResponse = await fetch('https://swarm.xixuer.cn/api/verify-captcha', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ captcha: values.captcha })
      });

      if (!captchaResponse.ok) {
        const captchaData = await captchaResponse.json();
        throw new Error(captchaData.error || '验证码错误');
      }

      // 打印values查看表单数据
      console.log('Form values:', values);

      // 验证码正确后继续注册
      const response = await fetch('https://swarm.xixuer.cn/api/register', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          username: values.registerUsername,
          password: values.registerPassword,
          email: values.registerEmail,
          email_code: values.emailCode
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '注册失败');
      }

      message.success('注册成功！请登录');
      setTimeout(() => {
        setIsLogin(true);
        form.resetFields();
      }, 1500);

    } catch (error) {
      message.error(error.message);
      loadCaptcha();
    } finally {
      setLoading(false);
    }
  };
  const onFinish = (values) => {
    if (isLogin) {
      // 如果是登录，调用handleLogin函数
      handleLogin(values);
    } else {
      // 如果是注册，调用handleRegister函数
      handleRegister(values);
    }
  };
  const onFinishFailed = () => {
    // 重置表单项的状态
    form.resetFields();
  };
  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const checkPasswordStrength = (password) => {
    const hasMinLength = password.length >= 15;
    const hasMinLengthWithRequirements = password.length >= 8 && /[a-z]/.test(password) && /\d/.test(password);
    const isValid = hasMinLength || hasMinLengthWithRequirements;

    setIsPasswordValid(isValid);

    if (password.length === 0) {
      setPasswordStrength(0);
    } else if (hasMinLength) {
      setPasswordStrength(4); // Very strong
    } else if (hasMinLengthWithRequirements) {
      setPasswordStrength(3); // Strong
    } else if (password.length >= 8) {
      setPasswordStrength(2); // Medium
    } else {
      setPasswordStrength(1); // Weak
    }
  };

  useEffect(() => {
    checkPasswordStrength(password);
  }, [password]);

  const getStrengthColor = (strength) => {
    switch (strength) {
      case 1: return "#ff4d4f";
      case 2: return "#faad14";
      case 3: return "#52c41a";
      case 4: return "#1f6e43";
      default: return "#d9d9d9";
    }
  };

  const getStrengthLabel = (strength) => {
    switch (strength) {
      case 1: return "弱";
      case 2: return "中";
      case 3: return "强";
      case 4: return "非常强";
      default: return "";
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const handleSendEmailCode = async () => {
    try {
      const email = form.getFieldValue('registerEmail');
      const imgCaptcha = form.getFieldValue('captcha');
      
      if (!email) {
        message.error('请输入邮箱地址');
        return;
      }
      if (!imgCaptcha) {
        message.error('请先输入图片验证码');
        return;
      }

      // 发送邮箱验证码
      const response = await fetch('https://swarm.xixuer.cn/api/send-email-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ 
          email,
          captcha: imgCaptcha  // 直接在发送邮箱验证码时一起验证图形验证码
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '发送验证码失败');
      }

      message.success('验证码已发送到邮箱');
      setCountdown(60);
      
    } catch (error) {
      message.error(error.message);
      loadCaptcha();
    }
  };

  return (
    <div>
      <canvas id="starry-background" class="starry-background"></canvas>
      <div className="main-all">
        {/* <Card className="card-out"> */}
          <Card className="card">
            <div className="card">
              <h2 className="title">SurveySage</h2>
              <p className="title-bot">智能问卷调查和数据分析平台</p>
            </div>
            <Form
            form={form} // 将表单实例传递给 Form 组件
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={onFinishFailed}
            >
              {isLogin ? (
                // 录表单
                <div className="content">
                  <h3 className="title-state">登录</h3>
                  <Form.Item
                    name="usernameOrEmail"
                    rules={[{ required: true, message: '请输入用户名或邮箱!' }]}
                  >
                    <Input id="username-or-email" placeholder="用户名或邮箱地址" type="text" required />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输密码!' }]}
                  >
                    <Input.Password id="password" required />
                  </Form.Item>
                  <Form.Item className="remember">
                    <Checkbox id="remember-me"></Checkbox>
                    <a className="forget-password" href='/forget' style={{ float: 'right' }}>忘记密码?</a>
                  </Form.Item>
                  <Form.Item
                    name="captcha"
                    rules={[{ required: true, message: '请输入验证码!' }]}
                  >
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <Input placeholder="验证码" style={{ width: '120px' }} />
                      <img 
                        src={captcha} 
                        alt="验证码"
                        style={{ height: '38px', cursor: 'pointer' }}
                        onClick={loadCaptcha}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button className="but" htmlType="submit" loading={loading}>
                      登录
                    </Button>
                    <a href="#" className="tap-bot" onClick={toggleForm}>没有账号？去创建</a>
                  </Form.Item>
                </div>
              ) : (
                // 注册表单
                <div className="content">
                  <h3 className="title-state">注册</h3>
                  <Form.Item
                    name="registerEmail"
                    rules={[{ required: true, message: '请输入邮箱!' }]}
                  >
                    <Input placeholder="your@email.com" />
                  </Form.Item>
                  <Form.Item
                    name="captcha"
                    rules={[{ required: true, message: '请输入图片验证码!' }]}
                  >
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Input placeholder="图片验证码" style={{ width: '120px' }} maxLength={4} />
                      <img 
                        src={captcha} 
                        alt="验证码"
                        style={{ height: '32px', cursor: 'pointer' }}
                        onClick={loadCaptcha}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="emailCode"
                    rules={[{ required: true, message: '请输入邮箱验证码!' }]}
                  >
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Input placeholder="邮箱验证码" style={{ flex: 1 }} />
                      <Button 
                        onClick={handleSendEmailCode}
                        disabled={countdown > 0}
                      >
                        {countdown > 0 ? `${countdown}秒` : '发送验证码'}
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="registerPassword"
                    rules={[{ required: true, message: '请输入密码!' }]}
                  >
                    <Input.Password 
                      placeholder="密码" 
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                    {/* {password.length > 0 && (
                      <div className="container">
                        <p className="password-strength-label" style={{ color: getStrengthColor(passwordStrength) }}>
                          密码强度: {getStrengthLabel(passwordStrength)}
                        </p>
                      </div>
                    )} */}
                  <Form.Item
                    name="confirmPassword"
                    dependencies={['registerPassword']}
                    hasFeedback
                    rules={[
                      { required: true, message: '请输入确认密码!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('registerPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('两次输入的密码不匹配!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="确认密码" />
                  </Form.Item>
                  <Form.Item
                    name="registerUsername"
                    rules={[{ required: true, message: '请输入用户名!' }]}
                  >
                    <Input placeholder="请输入用户名" />
                  </Form.Item>
                  <Form.Item>
                    <Button className="but"
                    htmlType="submit">
                      注册
                    </Button>
                    <a href="#" className="tap-bot" onClick={toggleForm}>已有账号？登录</a>
                  </Form.Item>
                </div>
              )}
            </Form>
            <div className="bottom">
              <a className="main-text" href="/">返回主页</a>
            </div>
          {/* </Card> */}
        </Card>
      </div>
    </div>
    
  );
}
export default Login;