export function initStarryBackground() {
  class StarryBackground {
    constructor() {
      this.canvas = document.getElementById('starry-background');
      this.ctx = this.canvas.getContext('2d');
      this.stars = [];
      this.meteors = [];
      this.lastTime = 0;
      this.meteorTimer = 0;
      this.mouseX = 0;
      this.mouseY = 0;
  
      this.resizeCanvas();
      this.createStars();
      this.animate = this.animate.bind(this);
      
      window.addEventListener('resize', () => this.resizeCanvas());
      this.canvas.addEventListener('mousemove', (e) => this.handleMouseMove(e));
    }
  
    resizeCanvas() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.createStars();
    }
  
    createStars() {
      this.stars = [];
      const numStars = Math.floor((this.canvas.width * this.canvas.height) / 1000);
      for (let i = 0; i < numStars; i++) {
        this.stars.push({
          x: Math.random() * this.canvas.width,
          y: Math.random() * this.canvas.height,
          radius: Math.random() * 1.5,
          alpha: Math.random(),
          direction: Math.random() * Math.PI * 2,
          speed: Math.random() * 0.5
        });
      }
    }
  
    handleMouseMove(e) {
      const rect = this.canvas.getBoundingClientRect();
      this.mouseX = e.clientX - rect.left;
      this.mouseY = e.clientY - rect.top;
      this.createMeteor();
    }
  
    createMeteor() {
      const meteor = {
        x: this.mouseX,
        y: this.mouseY,
        length: Math.random() * 80 + 10,
        speed: Math.random() * 10 + 5,
        angle: Math.random() * Math.PI / 4 - Math.PI / 8,
        life: 1
      };
      this.meteors.push(meteor);
    }
  
    drawStar(star) {
      this.ctx.beginPath();
      this.ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
      this.ctx.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;
      this.ctx.fill();
    }
  
    updateStar(star) {
      star.x += Math.cos(star.direction) * star.speed;
      star.y += Math.sin(star.direction) * star.speed;
      star.alpha = Math.sin(Date.now() / 1000) * 0.3 + 0.7;
  
      if (star.x < 0) star.x = this.canvas.width;
      if (star.x > this.canvas.width) star.x = 0;
      if (star.y < 0) star.y = this.canvas.height;
      if (star.y > this.canvas.height) star.y = 0;
    }
  
    drawMeteor(meteor) {
      const gradient = this.ctx.createLinearGradient(
        meteor.x, meteor.y,
        meteor.x + Math.cos(meteor.angle) * meteor.length,
        meteor.y + Math.sin(meteor.angle) * meteor.length
      );
      gradient.addColorStop(0, `rgba(255, 255, 255, ${meteor.life})`);
      gradient.addColorStop(1, 'transparent');
  
      this.ctx.beginPath();
      this.ctx.strokeStyle = gradient;
      this.ctx.lineWidth = 2;
      this.ctx.moveTo(meteor.x, meteor.y);
      this.ctx.lineTo(
        meteor.x + Math.cos(meteor.angle) * meteor.length,
        meteor.y + Math.sin(meteor.angle) * meteor.length
      );
      this.ctx.stroke();
    }
  
    updateMeteor(meteor) {
      meteor.x += Math.cos(meteor.angle) * meteor.speed;
      meteor.y += Math.sin(meteor.angle) * meteor.speed;
      meteor.life -= 0.02;
    }
  
    animate(currentTime) {
      requestAnimationFrame(this.animate);
  
      const deltaTime = currentTime - this.lastTime;
      this.lastTime = currentTime;
  
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  
      this.stars.forEach(star => {
        this.updateStar(star);
        this.drawStar(star);
      });
  
      this.meteors = this.meteors.filter(meteor => meteor.life > 0);
      this.meteors.forEach(meteor => {
        this.updateMeteor(meteor);
        this.drawMeteor(meteor);
      });
    }
  
    start() {
      this.animate(0);
    }
  }
  
  // 创建并启动星空背景
  document.addEventListener('DOMContentLoaded', () => {
      const starryBg = new StarryBackground();
      starryBg.start();
  });

  const starryBg = new StarryBackground();
  starryBg.start();
}