import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Add as AddIcon,
  History as HistoryIcon,
  Person as PersonIcon,
  LightMode as LightModeIcon,
  DarkMode as DarkModeIcon,
  Feedback as FeedbackIcon,
  Logout as LogoutIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Sidebar({ onNewConversation, onToggleHistory, onToggleTheme, onOpenProfile, theme, recentConversations, onSelect }) {
  const navigate = useNavigate();
  const [showRecentConversations, setShowRecentConversations] = useState(false);

  const commonButtonStyles = {
    sx: {
      width: '100%',
      justifyContent: 'flex-start',
      padding: '10px 16px',
      gap: '12px',
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(255,255,255,0.08)' 
          : 'rgba(0,0,0,0.04)',
        color: theme.palette.mode === 'dark'
          ? '#fff'
          : '#1a73e8'
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleMouseEnter = () => {
    setShowRecentConversations(true);
  };

  const handleMouseLeave = () => {
    setShowRecentConversations(false);
  };

  return (
    <Box className="sidebar" sx={{ width: 160 }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: '100%',
        py: 2,
      }}>
        <Box sx={{ mb: 2, px: 2, display: 'flex', alignItems: 'center' }}>
          <img 
            src="/images/logo.png" 
            alt="logo"
            style={{ 
              width: '28px',
              height: '28px',
              minWidth: '28px'
            }}
          />
          <span className="sidebar-text">
            SurveySage
          </span>
        </Box>

        <Box sx={{ mb: 2 }}>
          <IconButton className="sidebar-button" onClick={() => navigate('/')} {...commonButtonStyles}>
            <ArrowBackIcon sx={{ fontSize: '1.3rem' }} />
            <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>返回主页</span>
          </IconButton>
          <IconButton className="sidebar-button" onClick={onNewConversation} {...commonButtonStyles}>
            <AddIcon sx={{ fontSize: '1.3rem' }} />
            <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>新建对话</span>
          </IconButton>
          <IconButton className="sidebar-button" onClick={onToggleHistory} {...commonButtonStyles}>
            <HistoryIcon sx={{ fontSize: '1.3rem' }} />
            <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>历史对话</span>
          </IconButton>
        </Box>

        <Box sx={{ mb: 2 }}>
          {showRecentConversations && recentConversations.slice(0, 3).map(conv => (
            <IconButton 
              className="sidebar-button" 
              onClick={() => onSelect(conv.id)} 
              {...commonButtonStyles}
              key={conv.id}
              sx={{ padding: '4px', fontSize: '0.8rem' }}
            >
              <Typography variant="subtitle2" style={{ textAlign: 'center', flex: 1, fontSize: '0.8rem' }}>
                {conv.title || '未命名对话'}
              </Typography>
              <Typography variant="caption" color="text.secondary" style={{ fontSize: '0.7rem' }}>
                {new Date(conv.created_at).toLocaleString()}
              </Typography>
            </IconButton>
          ))}
        </Box>

        <Box sx={{ mt: 'auto' }}>
          <Tooltip title="个人信息" placement="right" arrow>
            <IconButton className="sidebar-button" onClick={onOpenProfile} {...commonButtonStyles}>
              <PersonIcon sx={{ fontSize: '1.3rem' }} />
              <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>个人信息</span>
            </IconButton>
          </Tooltip>
          <Tooltip title="主题切换" placement="right" arrow>
            <IconButton className="sidebar-button" onClick={onToggleTheme} {...commonButtonStyles}>
              {theme.palette.mode === 'dark' ? (
                <LightModeIcon sx={{ fontSize: '1.3rem' }} />
              ) : (
                <DarkModeIcon sx={{ fontSize: '1.3rem' }} />
              )}
              <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>切换主题</span>
            </IconButton>
          </Tooltip>
          <Box sx={{ mb: 2 }}>
            <Tooltip title="反馈" placement="right" arrow>
              <IconButton className="sidebar-button" onClick={() => {}} {...commonButtonStyles}>
                <FeedbackIcon sx={{ fontSize: '1.3rem' }} />
                <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>反馈</span>
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="退出" placement="right" arrow>
              <IconButton className="sidebar-button" onClick={handleLogout} {...commonButtonStyles}>
                <LogoutIcon sx={{ fontSize: '1.3rem' }} />
                <span className="sidebar-text" style={{ textAlign: 'center', flex: 1 }}>退出登录</span>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;