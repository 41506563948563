// ForgotPassword.js
import React, { useState, useEffect } from 'react';
import { Button, Input, Card, Form, message } from 'antd';
import './forget.css';
import { initStarryBackground } from './starry-background';

const ForgotPassword = () => {
  const [form] = Form.useForm();
  
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    initStarryBackground();
    loadCaptcha();
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const loadCaptcha = async () => {
    try {
      const response = await fetch('https://swarm.xixuer.cn/api/captcha', {
        method: 'GET',
        headers: {
          'Accept': 'image/jpeg',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include',
        mode: 'cors'
      });
      
      if (!response.ok) {
        throw new Error('加载验证码失败');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCaptcha(url);
      
      if (captcha) {
        URL.revokeObjectURL(captcha);
      }
    } catch (error) {
      console.error('加载验证码错误:', error);
      message.error('验证码加载失败，请刷新重试');
    }
  };

  const handleSendEmailCode = async () => {
    try {
      const email = form.getFieldValue('email');
      const imgCaptcha = form.getFieldValue('captcha');
      
      if (!email) {
        message.error('请输入邮箱地址');
        return;
      }
      if (!imgCaptcha) {
        message.error('请先输入图片验证码');
        return;
      }

      // 先验证图片验证码
      const captchaResponse = await fetch('https://swarm.xixuer.cn/api/verify-captcha', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ captcha: imgCaptcha.toLowerCase() })
      });

      const captchaData = await captchaResponse.json();
      if (!captchaResponse.ok) {
        throw new Error(captchaData.error || '验证码错误');
      }

      // 发送重置密码请求
      const response = await fetch('https://swarm.xixuer.cn/api/reset-password-request', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ email: email })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '发送验证码失败');
      }

      message.success('验证码已发送，请查收邮箱');
      setCountdown(60);
      loadCaptcha();

    } catch (error) {
      message.error(error.message);
      loadCaptcha();
    }
  };

  const handleResetPassword = async (values) => {
    try {
      setLoading(true);
      message.loading('正在处理请求...');

      // 直接发送重置密码请求
      const response = await fetch('https://swarm.xixuer.cn/api/reset-password', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          email: values.email,
          code: values.emailCode,
          new_password: values.newPassword
        })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || '重置密码失败');
      }

      message.success('密码重置成功！请返回登录');
      setTimeout(() => {
        window.location.href = '/login';
      }, 1500);

    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // 组件卸载时清理
  useEffect(() => {
    return () => {
      if (captcha) {
        URL.revokeObjectURL(captcha);
      }
    };
  }, []);

  return (
    <div className="container">
      <canvas id="starry-background" className="starry-background"></canvas>
      <Card className="card">
        <div className="card">
          <h2 className="title">SurveySage</h2>
          <p className="title-bot">智能问卷调查和数据分析平台</p>
        </div>
        <Form form={form} onFinish={handleResetPassword}>
          <h3 className="title-state">忘记密码</h3>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: '请输入邮箱!' },
              { type: 'email', message: '请输入有效的邮箱地址!' }
            ]}
          >
            <Input placeholder="邮箱地址" />
          </Form.Item>
          <Form.Item
            name="captcha"
            rules={[{ required: true, message: '请输入验证码!' }]}
          >
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Input placeholder="图片验证码" style={{ width: '120px' }} maxLength={4} />
              <img 
                src={captcha} 
                alt="验证码"
                style={{ height: '38px', cursor: 'pointer' }}
                onClick={loadCaptcha}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="emailCode"
            rules={[{ required: true, message: '请输入邮箱验证码!' }]}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              <Input placeholder="邮箱验证码" style={{ flex: 1 }} />
              <Button 
                onClick={handleSendEmailCode}
                disabled={countdown > 0}
              >
                {countdown > 0 ? `${countdown}秒` : '发送验证码'}
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: '请输入新密码!' }]}
          >
            <Input.Password placeholder="新密码" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: '请确认新密码!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次输入的密码不一致!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="确认新密码" />
          </Form.Item>
          <Form.Item>
            <Button className="but" htmlType="submit" loading={loading}>
              重置密码
            </Button>
            <a href="/login" className="tap-bot">返回登录</a>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;